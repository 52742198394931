import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Footer from "./footer";

import "../styles.scss";

const Layout = ({ children, useFooter = true }) => (
  <>
    <Helmet>
      <script src="https://kit.fontawesome.com/07a98a2a5d.js" crossorigin="anonymous"></script>
    </Helmet>
    {children}
    {useFooter && (
      <Footer />
    )}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
