import React from "react";

const Footer = () => (
  <footer>
    <h2>Stay in touch</h2>
    <ul>
      <li>
        <a href="mailto:philschanely@gmail.com"
          rel="noopener noreferrer"
          target="_blank"
          title="Shoot me an e-mail">
          <i class="far fa-envelope fa-5x"></i>
        </a>
      </li>
      <li>
        <a href="https://github.com/philschanely"
          rel="noopener noreferrer"
          target="_blank"
          title="View my work on Github">
          <i class="fab fa-github fa-5x"></i>
        </a>
      </li>
      <li>
        <a href="https://codepen.io/philschanely/"
          rel="noopener noreferrer"
          target="_blank"
          title="See some doodles on Codepen">
          <i class="fab fa-codepen fa-5x"></i>
        </a>
      </li>
    </ul>
    <p>Copyright &copy; 2019 by Phil Schanely. All rights reserved.</p>
  </footer>
);

export default Footer;
